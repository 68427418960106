:root {
  --color-scheme: dark;

  --font-family: system-ui;

  --fs-300: clamp(0.94rem, calc(0.92rem + 0.08vw), 0.98rem);
  --fs-400: clamp(1.13rem, calc(1.06rem + 0.33vw), 1.31rem);
  --fs-500: clamp(1.35rem, calc(1.21rem + 0.69vw), 1.75rem);
  --fs-600: clamp(1.62rem, calc(1.37rem + 1.24vw), 2.33rem);
  --fs-700: clamp(1.94rem, calc(1.54rem + 2.03vw), 3.11rem);
  --fs-800: clamp(2.33rem, calc(1.7rem + 3.15vw), 4.14rem);
  --fs-900: clamp(2.8rem, calc(1.85rem + 4.74vw), 5.52rem);

  --clr-primary-100: hsl(225, 14%, 95%);
  --clr-primary-300: hsl(219, 76%, 55%);
  --clr-primary-400: hsl(219, 76%, 40%);
  --clr-primary-500: hsl(219, 76%, 25%);
  --clr-secondary-300: hsl(269, 75%, 55%);
  --clr-secondary-400: hsl(269, 75%, 40%);
  --clr-secondary-500: hsl(269, 75%, 25%);
  --clr-secondaryRGBA-200: rgba(62,16,112, 0.2);
  --clr-secondaryRGBA-900: rgba(62,16,112, 0.9);
  --clr-accent-200: hsl(358, 85%, 80%);
  --clr-accent-300: hsl(358, 72%, 65%);
  --clr-accent-400: hsl(358, 72%, 50%);
  --clr-accent-500: hsl(358, 72%, 35%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  color-scheme: var(--color-scheme);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .content-grid,
 .full-width {
  --padding-inline: 1rem;
  --content-max-width: 900px;
  --breakout-max-width: 1200px;
  --quote-max-width: 700px;

  --breakout-size: calc(
    (var(--breakout-max-width) - var(--content-max-width)) / 2
  );
  /* min(100% - (var( --padding-inline) * 2), var( --content-max-width)) */
  display: grid;
  grid-template-columns: 
   [full-width-start]
   minmax(var( --padding-inline), 1fr) [breakout-start] minmax(0, var(--breakout-size))
   [content-start] min(((var(--padding-inline)/2) * 2), var(--content-max-width)) [quote-start] minmax(300px, var(--quote-max-width)) [quote-end]  min(((var(--padding-inline)/2) * 2),  var( --content-max-width)) [content-end]
   minmax(0, var(--breakout-size)) [breakout-end] minmax(var( --padding-inline), 1fr)
   [full-width-end];
}

.content-grid > .breakout {
  grid-column: breakout;
}
.content-grid > .quote {
  grid-column: quote;
  quotes: "«" "»";
  grid-template-columns: inherit;
  background-color: var(--clr-secondaryRGBA-900);
  border-radius: 12px;
  padding: 10px;
}
.content-grid > .full-width{
  grid-column: full-width;

  grid-template-columns: inherit;
}
 .content-grid > :not(.breakout, .full-width, .quote),
 .full-width > :not(.breakout, .full-width, .quote) {
  grid-column: content;
}


img.full-width:not(.hero-image) {
  width: 100%;
  max-height: 45vh;
  object-fit: cover;
}


body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--fs-400);
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.site-title {
  font-size: var(--fs-800);
  line-height: 1.05;
  text-transform: uppercase;
}

.section-title {
  font-size: var(--fs-800);
  line-height: 1.1;
}


.bg-primary {
  background: var(--clr-primary-500);
  
}
.bg-secondary {
  background: var(--clr-secondaryRGBA-200);
}
.bg-image {
  
  position: relative;
  height: 100vh;
  overflow-x: clip;
  
}
.hero-image{
  position: absolute;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: 0% 85%;
  /* -webkit-animation: heroImageFadeOut linear forwards;
  animation: heroImageFadeOut linear forwards; 
  animation-timeline: view();
  animation-range: exit;  */
}
.animation-image{
  animation-timeline: view();
  animation-range: exit; 
  -webkit-animation-name: heroImageFadeOut;
  -webkit-animation-duration:  1ms; /* Firefox requires this to apply the animation */
  -webkit-animation-timing-function: linear;
  animation-name: heroImageFadeOut;
  animation-duration: 1ms; /* Firefox requires this to apply the animation */
  animation-timing-function: linear;
}
@keyframes heroImageFadeOut{
25% {opacity: 1;}
85%,100% {opacity: 0; scale: 1.9}
}
.hero-text-content{
  -webkit-animation:  textfadeout linear;
  -webkit-animation-name:textfadeout;
  -webkit-animation-duration:  1ms; /* Firefox requires this to apply the animation */
  -webkit-animation-timing-function: linear;
  animation: textfadeout linear;
  animation-timeline: view();
  animation-range: exit -340px;
}

@keyframes textfadeout{
  to {
    opacity:0;
  }
}
.translucent{
  opacity: .3;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.call-to-action {
  padding: 1rem;
  border-radius: 12px;
  background: hsl(0 0% 100% / 0.15);
}
.call-to-action-hero {
  padding: 1rem;
  border-radius: 12px;
  background: var(--clr-secondaryRGBA-900);
}

.wrapper {
  width: calc(100% - 3rem);
  max-width: 900px;
  margin-inline: auto; 
}

.flow > * + * {
  margin-top: var(--flow-spacing, 1em);
}

.section-padding {
  padding-block: 2.5rem;
}
.section-padding-hero{
  padding-block: 12.5rem;
  margin-bottom: 6rem;
}
.primary-header {
  padding-block: 1rem;
  padding-inline: 1rem;
 
  background: var(--clr-primary-400);
  color: var(--clr-primary-100);
}

.primary-header__layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-width: 250px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: max(5vw, 1rem);
}

nav a {
  color: inherit;
  text-decoration: none;
}

nav a:hover,
nav a:focus {
  color: var(--clr-secondary-300);
 

}
@media (max-width:1200px){
.hero-image{
  height: 130%;
}


}
@media (max-width:600px){
  nav a{
    font-size: .8em;
  }
  .primary-header__layout {
    flex-direction: column;
  }
 .hero-image{
   overflow-x: clip;
 }
}
.even-columns {
  display: flex;
  gap: 1rem;
} 

.infinite-list{
  list-style: none;
  padding-inline: 0;
  margin: 0;
  background-color: var(--clr-secondaryRGBA-500);
  border-radius: 8px;
  gap:5px;
}
.infinite-list li{
  border-radius: 8px;
  padding: 1rem;
  box-shadow:  0 0.5rem 1rem -0.25rem var(--clr-primary-500);
}
.scroller{
  max-width: var(--content-max-width);
  /* outline:3px solid green; */
}
.scroller__inner{


  padding-block: 1rem;
  width: fit-content;
  display:flex;
  flex-wrap: wrap;
  gap:1rem;
  -webkit-animation: infiniteList var(--_animation-duration, 50s) var(--_animation-direction, forwards) linear infinite;
  animation: infiniteList var(--_animation-duration, 50s) var(--_animation-direction, forwards) linear infinite;
}
.scroller__inner[data-direction='right']{
  --_animation-direction:reverse;
  --_animation-duration:40s;
}
.scroller__inner[data-direction='left']{
  --_animation-direction:forwards;
  --_animation-duration:50s;
}
.scroller[data-animate='true']{
  overflow-x:hidden;

  -webkit-mask:linear-gradient(
  90deg,
  transparent,
  white 20%,
  white 80%,
  transparent);

  mask:linear-gradient(90deg,
  transparent,
  white 15%,
  white 85%,
  transparent);
}
.scroller[data-animate='true'] .scroller__inner{
  flex-wrap: nowrap;
}

@keyframes infiniteList{
  to{
    transform: translate(calc(-50% - 0.5rem));
  }
}
/* .scroll-watcher{
  height: 10px;
  position: fixed;
  background-color:lime;
  z-index: 1000;
  top:0;
  width: 100%;
  scale:0 1;
  animation: scroll-watcher linear;
  animation-timeline: scroll();
  transform-origin: left;
}

@keyframes scroll-watcher {
  to {scale: 1 1;}
} */

section > img:not(.hero-image){
opacity: 0;
/* -webkit-animation: fade-in linear forwards;
animation:fade-in linear forwards;
animation-timeline: view(600px 100px); */
}

.animation-snowboarder-image{
  -webkit-animation-name:fade-in;
  -webkit-animation-duration:  1ms; /* Firefox requires this to apply the animation */
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
animation-name:fade-in;
animation-fill-mode: both;
animation-timing-function: linear;
animation-duration: 1ms; 
animation-timeline: view(600px 100px);
}

@keyframes fade-in {
  from {
    scale: 0.9; opacity:0
  }
  to{
    scale: 1; opacity:1
  }
}
@media (min-width:1900px){
  .section-padding-hero{
    padding-block: 2.5rem;
  }

}
.footerContainer{
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  align-items: center;
}
.footerIconContainer{
  display: flex;
}

.footerNavContainer ul{
  display: grid;
  gap:1rem;
  padding-block: 25px;
}
.animationTitle{
  text-align: center;
}

.scroll-reveal  span{
  color:hsl(0 0% 100% / .2);
  background-image:linear-gradient(90deg, red, blue);
  background-clip: text;
  background-size: 0% 100%;
  background-repeat: no-repeat;
  /* -webkit-animation: scroll-reveal linear forwards;
  animation:scroll-reveal linear forwards;
  animation-timeline: view(); */

}
.animation-scroll-reveal{
  -webkit-animation-name:scroll-reveal;
  -webkit-animation-duration:  1ms; /* Firefox requires this to apply the animation */
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timeline: view();
  animation-name:scroll-reveal;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
/* .animation-snowboarder-image{
  animation-name:fade-in;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-duration: 1ms; 
  animation-timeline: view(600px 100px);
  } */
.scroll-reveal h2 span{

  animation-range-start: cover 15vh;
  animation-range-end: cover 45vh;
}
.scroll-reveal p span{

  animation-range-start: cover 44vh;
  animation-range-end: cover 75vh;
}
@keyframes scroll-reveal{

to{
  background-size:100% 100%;
}
}
